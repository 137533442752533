import logo from './Images/logo.jpeg';
import discord from './Images/discord.png';
import twitter from './Images/twitter.png';
import mygif from './Images/mygif.gif';
import img7 from './Images/7.jpeg';
import img8 from './Images/8.jpeg';
import img9 from './Images/9.jpeg';
import img10 from './Images/10.jpeg';
import img11 from './Images/11.jpeg';
import img12 from './Images/12.jpeg';
import team1 from './Images/team1.PNG';
import new1 from './Images/new1.PNG';
import new2 from './Images/new2.PNG';
import new3 from './Images/new3.PNG';
import new4 from './Images/new4.PNG';
import new5 from './Images/new5.PNG';
import team2 from './Images/team2.png';
import Marquee from "react-fast-marquee";
import './App.css';

function App() {
  return (
    <>
      <div>
        <section id="banner">
          <div className="my-banner-content">
            <nav className="navbar navbar-expand-lg">
              <a className="navbar-brand" href="#"><img src={logo} className="logo" /></a>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"><i className="fa fa-2x fa-bars" style={{color: 'white', fontSize: '18px', backgroundColor: 'black', padding: '10px'}} aria-hidden="true" /></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item active">
                    <a className="nav-link" href="#banner">Home <span className="sr-only">(current)</span></a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#about">About</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#roadmap1">Roadmap</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#vision">Vision</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#team">Team</a>
                  </li>
                  
                  <li className="nav-item">
                    <a className="nav-link social" href="https://discord.gg/VnpKTbTgRJ"><img src={discord} className="social-link" /></a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link social" href="#"><img src={twitter} className="social-link" /></a>
                  </li>
                </ul>
              </div>
            </nav>
            <div className="container">
              <div className="row">
                <div className="col-md-7 col-sm-12 col-lg-7 col-12 banner-content" data-aos="zoom-in">
                <h1 class="banner-h1-1">
                        Welcome to the
                    </h1>
                    <h2 className='banner2'>
                    LAZY LIZARD
                    </h2>
                    <h2 className='banner2 beach-banner'>
                    BEACH CLUB
                    </h2>
                    {/* <h1 class="banner-h1-3 first-h1-3">
                    
                    </h1>
                    <h1 class="banner-h1-3">
                    BEACH CLUB
                    </h1> */}
                  <p className="banner-p">
                  7,777 Lazy Lizards relaxing on the ethereum blockchain while earning passive income. Every Lizard is uniquely generated from over 100 hand-drawn traits. Every NFT you own is one ticket to our weekly giveaways.
                  </p>
                  <a href="#about" data-aos="flip-up"><button className="btn btn-lg mint-btn" >
                      Learn More 
                    </button></a>
                </div>
                <div className="col-md-5 col-sm-12 col-lg-5 col-12" data-aos="zoom-in-up">
                  <img src={mygif} className="mygif" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="about">
          <div className="about-cnt">
            <div className="container about-content">
              <h1 className="about-h1" data-aos="zoom-in">
                Who are The Lazy Lizards?
              </h1>
              <div className="row">
                <div className="col-md-1 col-sm-12 col-12 col-lg-1">
                </div>
                <div className="col-md-10 col-sm-12 col-12 col-lg-10 " data-aos="zoom-in-up">
                  <p className="about-p">
                  LLBC was created as an NFT community rewarded through Defi investments. Pfp NFTs bring a strong sense of community and defi brings insane revenue outside of NFT sales.
                  
                  We believe the most important factor in building a successful project is the community. What better way to build a strong community than reward holders consistently.
                  </p>
                  <p className='about-p'>
                  We will be giving away ETH, NFTs, and Nodes EVERY WEEK to NFT holders! 
                  </p>
                  <p className='about-p'>
                  How can we give away ETH every week and be profitable? We will be using a majority of minting proceeds to invest in various defi projects. Over time these investments will be diversified across several blockchains, between nodes, validators, stable coin yields, blue chip NFTs, and more speculative defi projects, with higher risk/reward.
                  
                  </p> 
                  <p className='about-p'>
                  Revenue from investments will be divided between compounding/reinvesting, community rewards, and team/contributors. The final percentages will be chosen by the community from several options, through a vote held in Discord.
                  </p>
                </div>
                <div className="col-md-1 col-sm-12 col-12 col-lg-1">
                </div>
              </div>
            </div>
          </div>
        </section>


        <section id="roadmap1" style={{position: 'relative', bottom: '8px'}}>
          <div className="roadmap-cnt">
            <section className="intro">
              <div className="container" data-aos="zoom-in">
                <div className="row">
                  <div className="col-md-2 col-sm-12 col-lg-2 col-12">
                  </div>
                  <div className="col-md-8 col-sm-12 col-lg-8 col-12">
                    <h1 className="roadmap-h1" data-aos="zoom-in">
                      The Roadmap
                    </h1>
                   
                  </div>
                  <div className="col-md-2 col-sm-12 col-lg-2 col-12">
                  </div>
                </div>
              </div>
            </section>
            <section id="roadmap" className="timeline" style={{paddingBottom: '50px'}}>
              <ul>
                <li className="in-view">
                  <div data-aos="zoom-in">
                    <time>Premint:</time><br />
                    <p>
                    Whitelist giveaways on Discord and Twitter. We want to reward our early supporters! Our first 100 Followers on Twitter will be eligible for WL. Our first 100 Discord members will receive the OG Lizard role and WL.
</p>
                  </div>
                </li>
                <li className="in-view">
                  <div data-aos="zoom-in">
                    <time>25% Mint</time><br /> 
                    <p>
                    Rarity tools application and 3 ETH airdropped between 3 lucky holders.
                    </p>
                  </div>
                </li>
                <li className="in-view">
                  <div data-aos="zoom-in">
                    <time>Investments</time><br /> 
                    <p>
                    As soon as we have capital, we will begin to make investments. Hop in the Discord to cast your vote.
                    </p>
                  </div>
                </li>    
                <li className="in-view">
                  <div data-aos="zoom-in">
                    <time>50% Mint</time><br /> 
                    <p>
                    $150,000 reserved for community rewards. This is in addition to revenue allocated to community rewards on a weekly basis. 

                    </p>
                  </div>
                </li>  
                <li className="in-view">
                  <div data-aos="zoom-in">
                    <time>Diversify Investments</time><br /> 
                    <p>
                    More capital means more investments and diversification. We don’t want to have all of our eggs in one basket! 
                    </p>
                   
                   
                  </div>
                </li>  
                <li className="in-view">
                  <div data-aos="zoom-in">
                    <time>75% mint</time><br /> 
                    <p>
                    Exclusive merch for all holders. Clothing will be fulfilled by Maddies, the largest NFT merch partner! P.S. We will be looking for community talent to partner with, tell your artist friends!
                    </p>
                  </div>
                </li>  
                <li className="in-view">
                  <div data-aos="zoom-in">
                    <time>100% Mint</time><br /> 
                    <p>
                    10 ETH airdropped between 10 lucky holders! Stick around to participate in voting and guide the future of Lazy Lizard Beach Club!
                    </p>
                  </div>
                </li>  
              </ul>
            </section>
          </div>
        </section>


       <section id="about">
          <div className="about-cnt">
            <div className="container vision-content">
              <h1 className="about-h1" data-aos="zoom-in">
                Our Mission
              </h1>
              <div className="row">
                <div className="col-md-1 col-sm-12 col-12 col-lg-1">
                </div>
                <div className="col-md-10 col-sm-12 col-12 col-lg-10 " data-aos="zoom-in-up">
                  <p className="about-p">
                  Our main Goal is to build a Strong and Well-Rewarded community. The compounding of nodes alone will increase revenue weekly, allowing us to reward more holders per week as time goes on. We want to work with great minds in the space and build lasting connections in web3. Join our Discord to learn about Roadmap 2.0.
                  </p>
                </div>
                <div className="col-md-1 col-sm-12 col-12 col-lg-1">
                </div>
              </div>

           
            </div>

            <div className='mymarquee' data-aos="zoom-in">
            <Marquee speed={200} gradient={false}>
                <img src={new1} className='nftimg'/>
                <img src={new2} className='nftimg'/>
                <img src={new3} className='nftimg'/>
                <img src={new4} className='nftimg'/>
                <img src={new5} className='nftimg'/>
                <img src={img7} className='nftimg'/>
                <img src={img8} className='nftimg'/>
                <img src={img9} className='nftimg'/>
                <img src={img10} className='nftimg'/>
              </Marquee>
              </div>
          </div>
        </section>


        <section id="team">
          <div className="team-cnt">
            <div className="container team-content">
              <h1 className="faq-h1 team-h1" data-aos="zoom-in">
                Our Team
              </h1>
              <div className="row team-row">
                <div className="col-md-6 col-sm-12 col-lg-6 col-12" style={{textAlign: 'center'}} >
                  <img src={team1} className="member-image" data-aos="flip-left"/>
                  <div data-aos="zoom-in-up">
                  <h2 className="member-name">
                  Artist / Founder
                  </h2>
                  <h2 className="designation">
                   Cayso.eth
                  </h2>
                  
                  </div>
                <a href="https://twitter.com/caysoeth?s=21"><img src={twitter} className="footer-icon" /></a>

                </div>
                <div className="col-md-6 col-sm-12 col-lg-6 col-12" style={{textAlign: 'center'}} >
                  <img src={team2} className="member-image" data-aos="flip-left"/>
                  <div data-aos="zoom-in-up">
                  <h2 className="member-name">
                  Launch Developers
                  </h2>
                  <h2 className="designation">
                  SVS / Latency Labs
                  </h2>
                  <p className='message'>
                  We want to thank The Sneaky Vampire Syndicate team for ensuring our launch goes smoothly. Their work is above industry standard! 
                  </p>
                  </div>
                <a href="https://twitter.com/svsnft?s=21"><img src={twitter} className="footer-icon" /></a>

                </div>
              </div>
            </div>
          </div>
        </section>
        
        <section id="footer" style={{position: 'relative', bottom: '20px'}}>
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-12 col-12 col-lg-4">
                <p className="footer-p">
                  Copyright © LLBC 2022.
                </p>
              </div>
              <div className="col-md-6 col-sm-12 col-12 col-lg-6">
              </div>
              <div className="col-md-2 col-sm-12 col-12 col-lg-2">
                <a href="#"><img src={twitter} className="footer-icon" /></a>
                <a href="https://discord.gg/VnpKTbTgRJ"><img src={discord} className="footer-icon" /></a>
              </div>
            </div>
          </div>
        </section>
        <a href="#banner"><i className="fa fa-2x fa-arrow-up" style={{position: 'fixed', bottom: '30px', right: '30px', color: '#1d1338', border: '1px solid #6feb9d', borderRadius: '0%', backgroundColor: '#6feb9d', height: '50px', width: '50px', padding: '10px'}} aria-hidden="true" /></a>
      </div>
    </>
  );
}

export default App;
